@font-face {
  font-family: "pixel";
  /*src: url(./VT323-Regular.ttf) format("truetype");*/
  src: url(./C64Mono.ttf) format("truetype");
}

.NextNumber {
  font-size: 5rem;
}

.Strikes {
  font-size: 4rem;
  color: red;
  text-align: center;
}

.App {
  text-align: center;
  background: black;
  color: #4AF626;
  font-family: orbitron, sans-serif;
  width: 100%;
}

.ScoreContainer {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  margin-top: 10px;
  max-width: 600px;
  margin-left: auto;
  margin-right: auto;
}

.GameOver {
  font-size: 3rem;
}

.ScoreSubmit {
  font-size: 1.5rem;
}

.ScoreSubmit input, button {
  margin: 10px;
  padding: 10px;
  font-size: 1.5rem;
  width: 150px;
}

.ScoreSubmit button {
  width: 180px;
}

.PiDisplay {
  font-size: 30px;
  color: white;
  letter-spacing: 4px;
  overflow: hidden;
}

.EatenPi {
  word-wrap: break-word;
  letter-spacing: 6px;
  font-size: 2rem;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
  max-width: 400px;
}

.LeaderboardTable {
  margin-left: auto;
  margin-right: auto;
}

.LeaderboardTable th, td {
  padding: 1px 15px;
}

.PiInput {
  width: 50px;
  margin-right: 15px;
  color: black;
}

input {
  background-color: black;
  border-color: #4AF626;
  color: #4AF626;  
}

.StartButton {
  font-size: 2rem;
  padding: 10px;
  margin-top: 15px;
  margin-bottom: 15px;
  margin-right: 15px;
}

button {
  color: #4AF626;
  border-color: #4AF626;
  background-color: black;
}

.SubmitScoreButton {
  margin-left: 5px;
}

.BlindMode {
  font-size: 2rem;
  padding: 10px;
  margin-top: 15px;
  margin-bottom: 15px;
  margin-right: 15px;
}

.Explanation {
  font-weight: bold;
}